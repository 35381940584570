import { IStenaUser, IStenaUserInput, IStenaUserFetchFilter, IHrNoteRestObject } from "../types/types";
import { IRequestResult, RequestResult } from "./graphRequests";


export type AutoActivationResult = "AUTO_ACTIVATED" | "SET_FOR_MANUAL_ACTIVATION";

export function submitStenaUser(stenaUser: IStenaUserInput): Promise<RequestResult<AutoActivationResult>> {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");

    const body = JSON.stringify(stenaUser);
    return fetch('stenauser/submituser', {
        method: 'POST',
        headers,
        body
    })
        .then(res => {
            if (res.status === 200 || res.status === 204) {
                const payload: AutoActivationResult = res.status === 200 ? "AUTO_ACTIVATED" : "SET_FOR_MANUAL_ACTIVATION";
                return { success: true, payload };
            } else {
                return Promise.reject(res.status.toString());

            }
        })
        .catch(catchMitten)
        .then((res) => new RequestResult(res));
}

export function updateStenaUser(token: string, stenaUser: IStenaUser): Promise<RequestResult<any>> {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `Bearer ${token}`);

    const body = JSON.stringify(stenaUser);

    return fetch('stenauser/updateuser', {
        method: 'PUT',
        headers,
        body
    })
        .then(res => {
            if (res.status < 300) {
                return { success: true };
            } else {
                return {
                    success: false,
                    failMessage: `Request to update user failed. Status code: ${res.status} message: ${res.statusText}`
                };
            }
        })
        .catch(catchMitten)
        .then((res) => new RequestResult(res));
}

export function getStenaUserById(token: string, id: string): Promise<RequestResult<IStenaUser>> {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `Bearer ${token}`);
    id = encodeURIComponent(id);
    return fetch(`stenauser/${id}`, {
        method: 'GET',
        headers
    })
        .then(res => {
            if (res.status < 300) {
                return res.json().then((payload: IStenaUser): IRequestResult<IStenaUser> => ({ success: true, payload }))
            } else {
                return {
                    success: false,
                    failMessage: `Failed to get user:${id}. Status code: ${res.status} message: ${res.statusText}`
                };
            }
        })
        .catch(catchMitten)
        .then((res) => new RequestResult(res));

}



export function getApplicationAccessToken(token: string): Promise<RequestResult<string>> {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `Bearer ${token}`);

    return fetch(`useractivation/GetApplicationToken`, {
        headers,
        method: 'GET'
    }).then(res => {
        if (res.status < 300) {
            return res.json()
                .then((obj): IRequestResult<string> => {
                    return { success: true, payload: obj.access_token };
                });
        } else return {
            success: false,
            failMessage: "request to get access token failed with status: " + res.status.toString()
        }
    })
        .catch(catchMitten)
        .then((res) => new RequestResult(res));
}

export function getAllPendingStenaUsersWithAzureData(token: string): Promise<RequestResult<{ activationQueueCount: number, users: IStenaUser[], activatedUpns: string[] }>> {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `Bearer ${token}`);
    return fetch("stenauser/allpending", {
        method: "GET",
        headers
    })
        .then(res => res.status < 300 ? res.json() : Promise.reject(`No users fetched. status: ${res.status}`))
        .then(userResponse => {
            return { success: true, payload: userResponse }
        })
        .catch(catchMitten)
        .then((res) => new RequestResult(res));
}

export function getActivatedStenaUsersWithOffset(token: string, fetchFilter: IStenaUserFetchFilter): Promise<RequestResult<IStenaUser[]>> {
    let { offset, count, sortingField, sortDesc, search } = fetchFilter
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `Bearer ${token}`);
    return fetch(`stenauser/allactivated?offset=${offset}&count=${count}&sortingField=${sortingField}&sortDesc=${sortDesc}&searchTerm=${search}`, {
        method: "GET",
        headers
    })
        .then(res => res)
        .then(res => res.status < 300 ? res.json() : Promise.reject(`No users fetched. status: ${res.status}`))
        .then(userArray => {
            return { success: true, payload: userArray }
        })
        .catch(catchMitten)
        .then((res) => new RequestResult(res));
}

export function resendActivationEmail(id: string): Promise<RequestResult<any>> {
    return fetch(`stenauser/resendemail?id=${id}`)
        .then(res => {
            return { success: res.status === 200 }
        })
        .catch(catchMitten)
        .then(res => new RequestResult(res));
}


export function getActivatedStenaUserCount(token: string): Promise<RequestResult<number>> {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `Bearer ${token}`);
    return fetch(`stenauser/activatedcount`, {
        method: "GET",
        headers
    })
        .then(res => {
            if (res.status < 300) return res.json();
            return Promise.reject("count not gotten");
        })
        .then(res => {
            return {
                success: true,
                payload: res
            }
        })
        .catch(catchMitten)
        .then((res) => new RequestResult(res));
}

export function getAllHrNotes(token: string): Promise<RequestResult<IHrNoteRestObject[]>> {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `Bearer ${token}`);
    return fetch('HrCommunication/getallnotes', {
        method: 'GET',
        headers
    })
        .then(res => {
            if (res.status === 200) return res.json();
            else return Promise.reject(res.status);
        })
        .then((notes: IHrNoteRestObject[]) => {
            return {
                success: true,
                payload: notes
            }
        })
        .catch(catchMitten)
        .then((res) => new RequestResult(res));
}

export function sendAccountIssueDelaySms(token: string, note: IHrNoteRestObject): Promise<RequestResult<IHrNoteRestObject>> {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `Bearer ${token}`);

    const body = JSON.stringify(note);
    return fetch('HrCommunication/sendDelaySms', {
        method: 'PUT',
        headers,
        body
    })
        .then(res => {
            if (res.status === 200) return res.json();
            else return Promise.reject(res.status)
        })
        .then((notes: IHrNoteRestObject) => {
            return {
                success: true,
                payload: notes
            }
        })
        .catch(catchMitten)
        .then((res) => new RequestResult(res));
}

export function sendAccountProcessDelaySms(token: string, note: IHrNoteRestObject): Promise<RequestResult<IHrNoteRestObject>> {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `Bearer ${token}`);

    const body = JSON.stringify(note);
    return fetch('HrCommunication/sendAccountProcessDelaySms', {
        method: 'PUT',
        headers,
        body
    })
        .then(res => {
            if (res.status === 200) return res.json();
            else return Promise.reject(res.status)
        })
        .then((notes: IHrNoteRestObject) => {
            return {
                success: true,
                payload: notes
            }
        })
        .catch(catchMitten)
        .then((res) => new RequestResult(res));
}

export function updateNote(token: string, note: IHrNoteRestObject): Promise<RequestResult<IHrNoteRestObject>> {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `Bearer ${token}`);

    const body = JSON.stringify(note);
    return fetch('HrCommunication/updatenote', {
        method: 'PUT',
        headers,
        body
    })
        .then(res => {

            if (res.status === 200) return res.json();
            else return Promise.reject(res.status)
        })
        .then((notes: IHrNoteRestObject) => {
            return {
                success: true,
                payload: notes
            }
        })
        .catch(catchMitten)
        .then((res) => new RequestResult(res));
}

export function resolveNote(token: string, note: IHrNoteRestObject): Promise<RequestResult<any>> {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `Bearer ${token}`);

    return fetch(`HrCommunication/removenote/${note.employeeId}`, {
        method: 'DELETE',
        headers
    })
        .then(res => {
            if (res.status === 200) return { success: true };
            else return Promise.reject(res.status)
        })
        .catch(catchMitten)
        .then((res) => new RequestResult(res));
}

export function sendCheckPhoneEmail(token: string, employeeId: string, stid?: string): Promise<RequestResult<IHrNoteRestObject>> {
    const headers = new Headers();
    headers.append("Authorization", `Bearer ${token}`);

    return fetch(`HrCommunication/sendCheckPhoneEmail?employeeId=${employeeId}&stid=${stid ?? ""}`, {
        method: 'GET',
        headers
    })
        .then(res => {
            if (res.status === 200) return res.json();
            else return Promise.reject(res.status)
        })
        .then((note: IHrNoteRestObject) => {
            return {
                success: true,
                payload: note
            }
        })
        .catch(catchMitten)
        .then((res) => new RequestResult(res));
}

function catchMitten(er: any) {
    console.error(er)
    const failMessage = (er) ? (er instanceof Error) ? er.message : er.toString() : "null";
    return { success: false, failMessage };
}
