import { IDialogData } from "../types/types";
import { Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';

interface ISimpleDialogProps {
  open: boolean;
  data?: IDialogData;
  callbackConfirm?: () => void;
  callbackDismiss?: (force?: "force") => void;
}

export default function SimplePrompt({ open, data, callbackConfirm, callbackDismiss }: ISimpleDialogProps) {
  if (!data) return null;
  let stayOpen = open;

  if (data.title === "LOADING") return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
    >
      <CircularProgress color="inherit"/>
    </Backdrop>
  );
  return (
    <Dialog
      open={stayOpen}
      aria-labelledby="dialogTitle"
      aria-describedby="dialogDescription"
      onClose={(event: any, reason: string)=>{
        if (callbackDismiss) callbackDismiss("force")
        stayOpen=false;
      }}
    >
      <DialogTitle id="dialogTitle">
        {data.title}
      </DialogTitle>
      <DialogContent id="dialogDescription">
       {/*  <DialogContentText id="dialogDescription"> */}
          {typeof data.description === "string" ? data.description : data.description()}
       {/*  </DialogContentText> */}
      </DialogContent>
      <DialogActions>
        {
          callbackConfirm ? (
            <>
              <Button onClick={() => {
                callbackConfirm();
                stayOpen = false;
              }}>{data.confirmButtonLabel ? data.confirmButtonLabel : "Yes"}</Button>
              <Button onClick={() => {
                if (callbackDismiss) callbackDismiss();
                stayOpen = false;
              }}
              >{data.dismissButtonLabel ? data.dismissButtonLabel : "No"}</Button>
            </>
          ) : (
            <Button onClick={() => {
              if (callbackDismiss) callbackDismiss();
              stayOpen = false;
            }}>{data.dismissButtonLabel ? data.dismissButtonLabel : "Ok"}</Button>
          )
        }
      </DialogActions>
    </Dialog>)
}