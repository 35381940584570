import React from "react";
import { Button, TextField } from '@mui/material';
// possibility to resend activation mail remains, we just need to return generated id from db.
import { IRequestResult, RequestResult, submitStenaUser, /*resendActivationEmail,*/ AutoActivationResult } from "../util";
import { IDialogData, IStenaUserInput, Screensize } from '../types/types';
import SimplePrompt from "./SimplePrompt";
import './DataSubmitForm.css';
import { AlreadyActivatedPromptContent } from "./promptContents/AlreadyActivatedPromptContent";
import { helpers } from "./helpers/helpers";
import { InputHolder, InputHolderStringPropertyKey } from "./helpers/inputHolder";

interface IDataSubmitFormProps {
  screensize: Screensize;
}

const UserInputKeys = {
  phone: "phone",
  repeatPhone: "repeatPhone",
  privateMail: "privateMail",
  repeatMail: "repeatMail",
  userInputPin: "userInputPin",
  userInputId: "userInputId",
  userCheckPrivacy: "userCheckPrivacy",
  userCheckPolicy: "userCheckPolicy"
}


type SubmitStatusOptions = {
  success: IDialogData;
  fail: IDialogData;
  conflict: IDialogData;
  loading: IDialogData;
  pending: undefined;
}

interface IDataSubmitFormState {
  inputHolder: InputHolder;
  loading: boolean,
  submitStatus: keyof SubmitStatusOptions;
  submitAttempted: boolean;
  dialogOpen: boolean;
  file: string;
  error: Error | null;
  inputDelay?: NodeJS.Timeout;
}

/**
 * CONSTANTS
 */
const holidaySubmissionMessage = () => (
  <div>
    <p>Your answers have been sent for review and you will be recieving further information when your account is verified.</p>
    <p>Please note that this might take longer than usual during the holiday season.</p>
  </div>
);

const DIALOG_OPTIONS: SubmitStatusOptions = {
  success: {
    title: "Thank You!",
    description: "Your answers have been sent for review and you will be recieving further information as soon as we have verified your account."
  },
  fail: {
    title: "Error.",
    description: () => <div><p>Server error.</p><p>Please try again at another time.</p><p>If problem persists, please contact <a href="mailto:itsupport@stena.com">itsupport@stena.com</a>.</p></div>
  },
  conflict: {
    title: "Already registered",
    description: () => <AlreadyActivatedPromptContent />,
    dismissButtonLabel: "Close",
    confirmButtonLabel: "Resend login information"
  },
  loading: {
    title: "LOADING",
    description: "LOADING"
  },
  pending: undefined
}


export const EMPLOYEE_ID_MIN_LENGTH = 8;
export const EMPLOYEE_ID_MAX_LENGTH = 13;


export type dataSubmitStringsKey = keyof typeof dataSubmitStringsLong;
export const dataSubmitStringsLong = {
  wrongPhoneFormat: "Invalid phonenumber format",
  wrongPrivateMailFormat: "Invalid mailadress format",
  outOfTries: "Out of tries. Please enter fields manually.",
  unvalidBirthDateFormat: "Invalid birthdate format",
  birthDateIncomplete: "Birthdate input is invalid",
  canOnlyContainNumbers: "Field can only contain numbers.",
  nameSuspect: "Are you sure this is correct?",
  submit: "Submit",
  loading: "Loading...",
  firstNameFieldLabel: "Given Name",
  lastNameFieldLabel: "Last Name",
  birthDateFieldLabelUserInput: "Birthdate (YYYY-MM-DD)",
  birthDateFieldLabelOrigo: "Birthdate",
  phoneFieldLabel: "Private Mobile No (+ccxxxxxx)",
  repeatPhoneFieldLabel: "Repeat phonenumber",
  noMatchField: "Doesn’t match",
  privateMailFieldLabel: "Private Email",
  repeatPrivateMailFieldLabel: "Repeat Email",
  privacyLabel: "I agree to the privacy agreement.",
  policyLabel: "I agree to the IT-policy.",
  beforeSubmitText: "Please update my account with the above details. Stena ABs IT User Policy and Information Security Policy apply. For more information on how your personal data is processed, please follow this ",
  "": ""
}


const dataSubmitStringsShort = {
  ...dataSubmitStringsLong,
  outOfTries: "Out of tries",
  idFieldHelperUserNotFound: "No id found.",
  idFieldHelperUserFound: "Id found.",
  wrongIdFormat: "Unvalid format.",
  nameSuspect: "Is this correct?"
}

const defaultState = (): IDataSubmitFormState => ({
  inputHolder: new InputHolder(),
  loading: false,
  submitStatus: 'pending',
  submitAttempted: false,
  dialogOpen: false,
  file: "",
  error: null,
});

class DataSubmitForm extends React.Component<IDataSubmitFormProps, IDataSubmitFormState> {

  private dataSubmitStrings;

  constructor(props: IDataSubmitFormProps) {
    super(props);
    this.state = defaultState();
    switch (this.props.screensize) {
      case Screensize.LARGE:
        this.dataSubmitStrings = dataSubmitStringsLong;
        break;
      default:
        this.dataSubmitStrings = dataSubmitStringsShort;
    }
  }



  private handleInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const id = e.target.id;

    const key = id as InputHolderStringPropertyKey;
    let value = e.target.value;

    this.setState((prevState) => {
      const newState: IDataSubmitFormState = { ...prevState };
      newState.inputHolder = prevState.inputHolder.copyWithUpdatedField(key, value);
      return newState;
    });
  }

  private formIncomplete = (): boolean => {
    return this.state.inputHolder.inputIncomplete("loose");
  }


  private submitAnswers = (): void => {
    let inputHolder = this.state.inputHolder;


    this.setState({
      loading: true
    })

    // make inputholder format itself and validate the results
    // check if names have unusual form, and warn user if so on first submit-attempt
    if (!this.state.submitAttempted && inputHolder.namesHaveUnusualForm) {
      setTimeout(() => {
        this.setState({
          submitAttempted: true,
          loading: false
        });
      }, 300);
      return;
    }

    // check if phone and mail match
    if (!inputHolder.phoneAndEmailReadyForSubmit) {

      inputHolder = inputHolder.copyWithUpdatedField("repeatMail", inputHolder.hasProperMailAddress ? this.state.inputHolder.repeatMail : "");
      inputHolder = inputHolder.copyWithUpdatedField("repeatPhone", inputHolder.hasProperPhoneNumber ? this.state.inputHolder.repeatPhone : "");

      this.setState({
        submitAttempted: true,
        loading: false,
        inputHolder
      });
      return;
    }

    // check if birthdate are incomplete or invalid
    if (inputHolder.inputIncomplete("tight")) {
      this.setState({
        submitAttempted: true,
        loading: false
      });
      return;
    }


    submitStenaUser(inputHolder.answersFormatedForSubmission)
      .then((result: RequestResult<AutoActivationResult>) => {
        if (result.success) {
          this.setState({
            submitStatus: "success",
            loading: false
          });
          return result
        } else {
          if (result.failMessage.trim() === "409") {
            this.setState({
              submitStatus: "conflict",
              error: { name: "Request fail", message: result.failMessage },
              loading: false
            });
          } else {
            this.setState({
              submitStatus: "fail",
              error: { name: "Request fail", message: result.failMessage },
              loading: false
            });
          }
          return result;
        }
      })
      .then((result: IRequestResult<string>) => {

      })
      .catch(er => {
        this.setState({
          error: er,
          loading: false
        });
      });


  }

  private resetPage = () => {
    const { submitStatus, error } = this.state;
    switch (submitStatus) {
      case 'fail':
        this.setState({
          ...defaultState(),
          error
        });
        return;
      default:
        this.setState({
          ...defaultState()
        });
    }
  }


  public render(): React.ReactElement<IDataSubmitFormProps> {
    const { loading, submitStatus, submitAttempted, inputHolder } = this.state;
    const showPrompt = loading ? "loading" : submitStatus;

    const inputsActive = !loading && submitStatus === "pending";
    const firstNameInputError = inputsActive && submitAttempted && helpers.containsNonWordChars(inputHolder.firstName.trim());
    const lastNameInputError = inputsActive && submitAttempted && helpers.containsNonWordChars(inputHolder.lastName.trim());
    const privateMailInputError = inputsActive && submitAttempted && !inputHolder.hasProperMailAddress;
    const birthDateInputError = inputsActive && ((submitAttempted && !inputHolder.hasCompleteBirthdate) || !inputHolder.hasIncompleteButProperBirthdate)
    const mailMatch = inputHolder.mailMatch(submitAttempted ? "complete" : "partial");
    const phoneMatch = inputHolder.phoneMatch(submitAttempted ? "complete" : "partial");
    const repeatMailError = inputsActive && !mailMatch;
    const repeatPhoneError = inputsActive && !phoneMatch;

    return (
      <div className='SubmitFormContainer'>
        <SimplePrompt open={showPrompt !== "pending"} data={DIALOG_OPTIONS[showPrompt]} callbackDismiss={this.resetPage} />
        <div>
          <div className="text-inputs">
            <form className="textFieldDiv" autoComplete="off" >
              <TextField size='small' autoComplete={this.props.screensize === Screensize.LARGE ? "new-password" : ""} label={this.dataSubmitStrings.firstNameFieldLabel} error={firstNameInputError} helperText={firstNameInputError && this.dataSubmitStrings.nameSuspect} id="firstName" onChange={this.handleInput} style={{}} fullWidth variant="outlined" value={inputHolder.firstName} />
              <TextField size='small' autoComplete={this.props.screensize === Screensize.LARGE ? "new-password" : ""} label={this.dataSubmitStrings.lastNameFieldLabel} error={lastNameInputError} helperText={lastNameInputError && this.dataSubmitStrings.nameSuspect} id="lastName" onChange={this.handleInput} style={{}} fullWidth variant="outlined" value={inputHolder.lastName} />
            </form>
            <form className="textFieldDiv" autoComplete="off" >

              <TextField style={{ flexBasis: "100%" }} size='small' type="tel" autoComplete={this.props.screensize === Screensize.LARGE ? "new-password" : ""} label={this.dataSubmitStrings.birthDateFieldLabelUserInput} id="birthDate" error={birthDateInputError} helperText={birthDateInputError && this.dataSubmitStrings.birthDateIncomplete} onChange={this.handleInput} fullWidth variant="outlined" value={inputHolder.birthDate} />
            </form>
            {this.props.screensize === Screensize.LARGE ? (
              <>
                <form className="textFieldDiv" autoComplete="off" >
                  <TextField size='small' type="tel" autoComplete={"new-password"} label={this.dataSubmitStrings.phoneFieldLabel} error={submitAttempted && !inputHolder.hasProperPhoneNumber} helperText={submitAttempted && !inputHolder.hasProperPhoneNumber && this.dataSubmitStrings.wrongPhoneFormat} id={UserInputKeys.phone} onChange={this.handleInput} fullWidth style={{}} variant="outlined" value={inputHolder.phone} />
                  <TextField size='small' type="email" autoComplete={"new-password"} autoCorrect="false" label={this.dataSubmitStrings.privateMailFieldLabel} error={privateMailInputError} helperText={privateMailInputError && this.dataSubmitStrings.wrongPrivateMailFormat} id={UserInputKeys.privateMail} onChange={this.handleInput} fullWidth style={{}} variant="outlined" value={inputHolder.privateMail} />
                </form>
                <form className="textFieldDiv" autoComplete="off" >
                  <TextField size='small' type="tel" autoComplete={this.props.screensize === Screensize.LARGE ? "new-password" : ""} label={this.dataSubmitStrings.repeatPhoneFieldLabel} error={!phoneMatch} helperText={!phoneMatch && this.dataSubmitStrings.noMatchField} id={UserInputKeys.repeatPhone} onChange={this.handleInput} fullWidth style={{}} variant="outlined" value={inputHolder.repeatPhone} />
                  <TextField size='small' type="email" autoComplete={this.props.screensize === Screensize.LARGE ? "new-password" : ""} autoCorrect="false" label={this.dataSubmitStrings.repeatPrivateMailFieldLabel} error={!mailMatch} helperText={!mailMatch && this.dataSubmitStrings.noMatchField} id={UserInputKeys.repeatMail} onChange={this.handleInput} fullWidth style={{}} variant="outlined" value={inputHolder.repeatMail} />
                </form>
              </>) : (
              <>
                <form className="textFieldDiv" autoComplete="off" >
                  <TextField size='small' type="tel" autoComplete={""} label={this.dataSubmitStrings.phoneFieldLabel} error={!inputHolder.hasProperPhoneNumber} helperText={!inputHolder.hasProperPhoneNumber && this.dataSubmitStrings.wrongPhoneFormat} id={UserInputKeys.phone} onChange={this.handleInput} fullWidth style={{}} variant="outlined" value={inputHolder.phone} />
                  <TextField size='small' type="tel" autoComplete={""} label={this.dataSubmitStrings.repeatPhoneFieldLabel} error={repeatPhoneError} helperText={repeatPhoneError && this.dataSubmitStrings.noMatchField} id={UserInputKeys.repeatPhone} onChange={this.handleInput} fullWidth style={{}} variant="outlined" value={inputHolder.repeatPhone} />
                </form>
                <form className="textFieldDiv" autoComplete="off" >
                  <TextField size='small' type="email" autoComplete={""} autoCorrect="false" label={this.dataSubmitStrings.privateMailFieldLabel} error={privateMailInputError} helperText={privateMailInputError && this.dataSubmitStrings.wrongPrivateMailFormat} id={UserInputKeys.privateMail} onChange={this.handleInput} fullWidth style={{}} variant="outlined" value={inputHolder.privateMail} />
                  <TextField size='small' type="email" autoComplete={""} autoCorrect="false" label={this.dataSubmitStrings.repeatPrivateMailFieldLabel} error={repeatMailError} helperText={repeatMailError && this.dataSubmitStrings.noMatchField} id={UserInputKeys.repeatMail} onChange={this.handleInput} fullWidth style={{}} variant="outlined" value={inputHolder.repeatMail} />
                </form>
              </>
            )}
            <form className="textFieldDiv">
              {/* Checkboxes disabled, plain text used instead
               <FormGroup className="bottomFormGroup">
                <FormControlLabel control={<Checkbox id={UserInputKeys.userCheckPrivacy} disabled={!this.userFound} checked={userCheckPrivacy !== ""} onChange={this.handleInput} />} className="checkBoxField" label={this.dataSubmitStrings.privacyLabel} />
                <FormControlLabel control={<Checkbox id={UserInputKeys.userCheckPolicy} disabled={!this.userFound} checked={userCheckPolicy !== ""} onChange={this.handleInput} />} className="checkBoxField" label={this.dataSubmitStrings.policyLabel} />
              </FormGroup> */}
              <div className="bottomFormGroup plainText">
                <p>{this.dataSubmitStrings.beforeSubmitText}<a href="https://life.stenaline.com/legal/privacypolicy"> link.</a></p>
              </div>
            </form>
          </div>
          {/*FOR LATER IMPLEMENTATION
                  <Button size="large" variant="outlined" component="label" >
                    Upload image
                    <input type='file' id="file" accept="image/*" onChange={this.uploadImage} hidden />
            </Button>*/}
          <Button disabled={this.formIncomplete() || loading} fullWidth size="medium" onClick={() => { this.submitAnswers(); }} variant="outlined">{this.dataSubmitStrings.submit}</Button>
        </div>
      </div>
    );
  }
}

export default DataSubmitForm;