import React from "react";
import { Screensize } from "../types/types";
import DataSubmitForm from "./DataSubmitForm";
import './LoggedOut.css';
import { MicrosoftButton } from "./MicrosoftButton";

interface ILoggedOutProps {
  screensize: Screensize;
}

interface ILoggedOutState {

}


const registerNewEmployeeWithoutStaffCardWelcomeText = "Please type in your name and birthdate in the form bellow. These will be used to identify the account that has been set up for you. In order to identify you, connect with you and provide you with login information securely, Stena Line requires your email address and mobile number to complete your account. Once Stena Line has activated your account you will receive a text and an email message with login information.";

class LoggedOut extends React.Component<ILoggedOutProps, ILoggedOutState> {


  public render(): React.ReactElement<ILoggedOutProps> {
    const welcomeText = registerNewEmployeeWithoutStaffCardWelcomeText;
    return (
      <div className='LoggedOut'>
        <div className='box'>
          <img alt="" className="stenaLogo headerItem" src={process.env.PUBLIC_URL + "/stena_big_header.jpeg"/* "https://www.stenaline.se/content/experience-fragments/stenaline/se/sv/site/header/master/_jcr_content/root/container/image_1099437675.coreimg.jpeg/1662452886384/stenaline-digital-rgb-positiv.jpeg" */} />
          <div className="formDiv">
            <p className="loginText headerItem" >{welcomeText}</p>
            <DataSubmitForm screensize={this.props.screensize} />
          </div>
        </div>
        <div className="microsoftButtonDiv">
          <MicrosoftButton signIn={true} />
        </div>
      </div>
    );
  }
}

export default LoggedOut;


//F1 F3 E1 E3 P1 P2