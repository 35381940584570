import { IStenaUserInput } from "../../types/types";
import { helpers } from "./helpers";



export type InputHolderStringPropertyKey = 'firstName'
    | 'lastName'
    | 'birthDate'
    | 'phone'
    | 'repeatPhone'
    | 'privateMail'
    | 'repeatMail'

export class InputHolder {
    firstName: string;
    lastName: string;
    birthDate: string;
    phone: string;
    repeatPhone: string;
    privateMail: string;
    repeatMail: string;



    constructor(other: InputHolder | undefined = undefined) {
        this.phone = other?.phone ?? "";
        this.repeatPhone = other?.repeatPhone ?? "";
        this.privateMail = other?.privateMail ?? "";
        this.repeatMail = other?.repeatMail ?? "";
        this.firstName = other?.firstName ?? "";
        this.lastName = other?.lastName ?? "";
        this.birthDate = other?.birthDate ?? "";
        this.copyWithUpdatedField.bind(this);
    }

    copyWithUpdatedField(key?: InputHolderStringPropertyKey, value?: string): InputHolder {
        if (key) {
            if (key === "birthDate") {
                value = helpers.appendBirthDateDemarkers(value ?? "", this.birthDate);
            }
            this[key] = value ?? "";
        }
        return new InputHolder(this);
    }

    phoneMatch(extent: "complete" | "partial"): boolean {
        if (extent === "partial")
            return this.repeatPhone.trim() === this.phone.trim().substring(0, this.repeatPhone.trim().length) && this.repeatPhone.trim().length <= this.phone.trim().length;
        else
            return helpers.isProperPhoneNumber(this.phone) && helpers.proccessPhoneNumber(this.repeatPhone) === helpers.proccessPhoneNumber(this.phone);
    }


    get hasProperPhoneNumber(): boolean {
        return helpers.isProperPhoneNumber(this.phone);
    }

    get hasProperMailAddress(): boolean {
        return helpers.isProperMailAdress(this.privateMail);
    }

    mailMatch(extent: "complete" | "partial"): boolean {
        return this.repeatMail.trim() === this.privateMail.trim().substring(0, this.repeatMail.trim().length) && ((extent === "complete" && this.repeatMail.trim().length === this.privateMail.trim().length) || (extent === "partial" && this.repeatMail.trim().length <= this.privateMail.trim().length));
    }


    get hasIncompleteButProperBirthdate(): boolean {
        return helpers.isProperBirthDate(this.birthDate, "building");
    }

    get hasCompleteBirthdate(): boolean {
        return helpers.isProperBirthDate(this.birthDate, "complete");
    }

    get phoneAndEmailReadyForSubmit(): boolean {
        return this.hasProperPhoneNumber && this.hasProperMailAddress && this.phoneMatch("complete") && this.mailMatch("complete");
    }

    get namesHaveUnusualForm(): boolean {
        return helpers.containsNonWordChars(this.firstName.trim() + this.lastName.trim())
    }

    formatPhoneInput(): void {
        this.phone = helpers.proccessPhoneNumber(this.phone);
    }

    formatForSubmission(): void {
        this.formatPhoneInput();
        this.privateMail = this.privateMail.trim();
        this.repeatMail = this.repeatMail.trim();
        this.firstName = this.firstName.trim();
        this.lastName = this.lastName.trim();
        this.birthDate = this.birthDate.trim();
    }

    get answersFormatedForSubmission(): IStenaUserInput {
        this.formatForSubmission();
        this.repeatPhone = this.phone;
        return {
            firstName: this.firstName.trim(),
            lastName: this.lastName.trim(),
            birthDate: this.birthDate.trim(),
            phoneNumber: this.phone.trim(),
            email: this.privateMail.trim(),
            activated: false
        }
    }

    inputIncomplete(strictness: "tight" | "loose"): boolean {
        const { firstName, lastName, birthDate, privateMail, repeatMail, phone, repeatPhone } = this;
        const anyFieldNotFilledIn = firstName === "" || lastName === "" || birthDate === "" || privateMail === "" || repeatMail === "" || phone === "" || repeatPhone === "";
        const invalidBirthDateFormat = !helpers.isProperBirthDate(birthDate, "complete")
        const formIsIncomplete = strictness === "loose" ? anyFieldNotFilledIn : anyFieldNotFilledIn || invalidBirthDateFormat;

        return formIsIncomplete;
    }
}