export const msalConfig = {
  auth: {
    clientId: "66d52157-feee-4285-bbec-7d5122b75f0b",//"20ccf32d-b4cf-43e6-9b3d-5a5c536999ff",
    authority: "https://login.microsoftonline.com/organizations", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: "https://stenalogin.azurewebsites.net/"
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["User.Read"],
};

export const loginRequestBe = {
  scopes: ["api://66d52157-feee-4285-bbec-7d5122b75f0b/Read.All"]
}

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};