import React from "react";
import { requestAccessTokenBE } from "../requestAccessToken";
import { azureAdminGroupName, azureHRGroupName, azureReaderGroupName } from "../AppConfig";
import EmployeeTable from "./EmployeeTable";
import Header from "./Header";
import './LoggedIn.css';
import { MicrosoftButton } from "./MicrosoftButton";
import { AzureInfo, IActivationResult, IHrDataMap, IHrNoteRestObject, IStenaUser, Screensize, SmsType } from "../types/types";
import { activateUserNew, checkIfGroupExists, getAdUser, getGroupMembers } from "../util/graphRequests";
import ReadEmployeeTable from "./ReadEmployeeTable";
import HREmployeeTable from "./HREmployeeTable";
import SimplePrompt from "./SimplePrompt";
import { getAllHrNotes, getAllPendingStenaUsersWithAzureData, getStenaUserById, resolveNote, sendCheckPhoneEmail, sendAccountIssueDelaySms, sendAccountProcessDelaySms, updateNote, updateStenaUser } from "../util";

interface ILoggedInProps {
  context: any;
  screensize: Screensize;
}

export type AccessLevel = "hasAdminAccess" | "hasReadAccess" | "hasHRAccess" | "hasNoAccess"
export type UpdateType = "session" | "permanent" | "restore";
export type ActiveView = "hr" | "admin" | "read";

interface ILoggedInState {
  pageNotReady: boolean;
  employeeTableLoading: boolean;
  accessLevel: AccessLevel;
  activeView: ActiveView;
  pendingUsers: IStenaUser[];
  activatedUpns: string[];
  activationResults: IActivationResult[];
  activationResultsViewOpen: boolean;
  hrNotesForPending: IHrDataMap;
  hrNotesForAutoActivated: IHrDataMap;
  activationQueueCount: number;
}

class LoggedIn extends React.Component<ILoggedInProps, ILoggedInState> {

  constructor(props: ILoggedInProps) {
    super(props);

    this.state = {
      pageNotReady: true,
      employeeTableLoading: false,
      accessLevel: "hasNoAccess",
      activeView: 'read',
      pendingUsers: [],
      activatedUpns: [],
      activationResults: [],
      activationResultsViewOpen: false,
      hrNotesForPending: {},
      hrNotesForAutoActivated: {},
      activationQueueCount: 0
    };
  }

  private getHrPendingUsers = () => {
    const employeeIdsWithNotesNotHidden: string[] = [];

    for (const key in this.state.hrNotesForPending) {
      if (!this.state.hrNotesForPending[key].tags?.includes("Hidden")) {
        employeeIdsWithNotesNotHidden.push(key);
      }
    }

    return this.state.pendingUsers.filter(user => employeeIdsWithNotesNotHidden.includes(user.id));
  }

  // private getFilteredUsers = () => {
  //   return this.getHrPendingUsers().filter((user => {
  //     let hidden = false;

  //     const tags = this.state.hrNotesForPending[user.employeeId]?.tags;
  //     tags.length > 0 && tags.map(tag => (tag === "Hidden") && (hidden = true))
  //     return !hidden;
  //   }))
  // }

  public async componentDidMount(): Promise<void> {
    let accessLevel: AccessLevel = "hasNoAccess"

    //Check if user is part of admin group
    const result = await checkIfGroupExists(await requestAccessTokenBE(this.props.context), azureAdminGroupName)
    if (result.success) {
      const groupId = result.payload;
      const response = await getGroupMembers(await requestAccessTokenBE(this.props.context), groupId)
      if (!response.success) {
        console.log(response.failMessage)
      }
      const isMember = response.payload.members.filter((member: { id: string | any[]; }) => member.id.includes(this.props.context.accounts[0].localAccountId));
      if (isMember.length > 0) {
        accessLevel = "hasAdminAccess"
      }
    }
    if (accessLevel === "hasNoAccess") {
      //Check if user is part of HR group
      const result = await checkIfGroupExists(await requestAccessTokenBE(this.props.context), azureHRGroupName)
      if (result.success) {
        const groupId = result.payload;
        const response = await getGroupMembers(await requestAccessTokenBE(this.props.context), groupId)
        const isMember = response.payload.members.filter((member: { id: string | any[]; }) => member.id.includes(this.props.context.accounts[0].localAccountId));
        if (isMember.length > 0) {
          accessLevel = "hasHRAccess";
        }
      }
    }
    if (accessLevel === "hasNoAccess") {
      //Check if user is part of reader group
      const result = await checkIfGroupExists(await requestAccessTokenBE(this.props.context), azureReaderGroupName)
      if (result.success) {
        const groupId = result.payload;
        await getGroupMembers(await requestAccessTokenBE(this.props.context), groupId).then((response) => {
          const isMember = response.payload.members.filter((member: { id: string | any[]; }) => member.id.includes(this.props.context.accounts[0].localAccountId));
          if (isMember.length > 0) {
            accessLevel = "hasReadAccess";
          }
        });
      }

    }

    const hrNoteResponse = await getAllHrNotes(await requestAccessTokenBE(this.props.context));
    if (hrNoteResponse.success) {
      const noteMap: IHrDataMap = {};
      const activatedNoteMap: IHrDataMap = {};
      hrNoteResponse.payload.forEach(note => {
        if (note.activationIssues) {
          activatedNoteMap[note.employeeId] = note;
        } else {
          noteMap[note.employeeId] = note;
        }
      });
      this.setState({ hrNotesForPending: noteMap, hrNotesForAutoActivated: activatedNoteMap });
    }

    if (["hasAdminAccess", "hasHRAccess"].includes(accessLevel)) {
      const token = await requestAccessTokenBE(this.props.context);
      const unactivatedResponse = await getAllPendingStenaUsersWithAzureData(token);
      if (unactivatedResponse.success) {
        var hiddenEmpIds = Object.entries(this.state.hrNotesForPending).filter(kvp => kvp[1].tags.includes("Hidden")).map(kvp => kvp[0]);
        const unactivatedUsers = accessLevel === "hasHRAccess" ? unactivatedResponse.payload.users.filter(user => !hiddenEmpIds.includes(user.id)) : unactivatedResponse.payload.users;
        this.setState({ pendingUsers: unactivatedUsers, activationQueueCount: unactivatedResponse.payload.activationQueueCount, activatedUpns: unactivatedResponse.payload.activatedUpns })
      }
    }

    let activeView: ActiveView = "read";
    if (accessLevel === "hasHRAccess") activeView = "hr";
    if (accessLevel === "hasAdminAccess") activeView = "admin";
    this.setState({ accessLevel, activeView, pageNotReady: false });


  }

  private getHasAdminAccessView = () => {
    const { pendingUsers } = this.state
    if (this.state.employeeTableLoading) return this.loadingView();
    switch (this.state.activeView) {
      case "admin":
        return (
          <div className="hasAccessDiv">
            <EmployeeTable
              activationQueueCount={this.state.activationQueueCount}
              accessLevel={this.state.accessLevel}
              context={this.props.context}
              activatedUpns={this.state.activatedUpns}
              activationResultsViewOpen={this.state.activationResultsViewOpen}
              toggleActivationResultsView={(open: boolean) => { this.setState({ activationResultsViewOpen: open }) }}
              users={pendingUsers}
              hrNotes={this.state.hrNotesForPending}
              activationResults={this.state.activationResults}
              updateUser={this.updateUser}
              activateUserCallback={this.activatePendingUser}
              switchView={this.switchView}
              sendSms={this.dispatchSms}
              updateNote={this.sendNoteForUpdate}
              sendCheckPhoneEmail={this.sendCheckPhoneEmail}
            />
          </div>);
      case "read":
        return (
          <ReadEmployeeTable
            accessLevel={this.state.accessLevel}
            screensize={this.props.screensize}
            context={this.props.context}
            switchView={this.switchView}
            hrNotes={this.state.hrNotesForAutoActivated}
            resolveNote={this.resolveHrNote}
          />);
      case "hr":
        return (
          <HREmployeeTable
            accessLevel={this.state.accessLevel}
            screensize={this.props.screensize}
            context={this.props.context}
            users={this.getHrPendingUsers()}
            hrNotes={this.state.hrNotesForPending}
            switchView={this.switchView}
            updateNote={this.sendNoteForUpdate}
          />);
    }

  }



  private getNoAccessView = () => {
    return (
      <div className="noAccessDiv">
        <img alt="no access" className="noAccessImage" src="https://cdn-icons-png.flaticon.com/512/2500/2500762.png" />
        <h1 className="noAccessHeader">Access Restricted</h1>
        <p className="noAccessText">You don't have permission to access this page. Please contact an administrator to request access. </p>
        <MicrosoftButton signIn={false} />
      </div>
    )
  }

  private getReaderAccessView = () => {
    return (
      <div className="hasAccessDiv">
        <ReadEmployeeTable
          accessLevel={this.state.accessLevel}
          screensize={this.props.screensize}
          context={this.props.context}
          hrNotes={this.state.hrNotesForAutoActivated}
          resolveNote={this.resolveHrNote}
        />
      </div>
    )
  }

  private getHRAccessView = () => {

    switch (this.state.activeView) {
      case "read":
        return (
          <ReadEmployeeTable
            accessLevel={this.state.accessLevel}
            screensize={this.props.screensize}
            context={this.props.context}
            switchView={this.switchView}
            hrNotes={this.state.hrNotesForAutoActivated}
            resolveNote={this.resolveHrNote}
          />);
      case "hr":
        return (
          <HREmployeeTable
            accessLevel={this.state.accessLevel}
            screensize={this.props.screensize}
            context={this.props.context}
            users={this.getHrPendingUsers()}
            hrNotes={this.state.hrNotesForPending}
            switchView={this.switchView}
            updateNote={this.sendNoteForUpdate}
          />);
    }
  }

  private loadingView() {
    return <SimplePrompt open={this.state.pageNotReady} data={{ title: "LOADING", description: "" }} />
  }

  private getView = () => {
    switch (this.state.accessLevel) {
      case "hasAdminAccess": return this.getHasAdminAccessView();
      case "hasHRAccess": return this.getHRAccessView();
      case "hasReadAccess": return this.getReaderAccessView();
      default: return this.getNoAccessView()
    }
  }

  private switchView = (view: ActiveView) => {
    this.setState({ activeView: view })
  }

  private updateUser = async (userToUpdate: IStenaUser, updateType: UpdateType) => {
    switch (updateType) {
      case "permanent":
        this.updateUserPermanent(userToUpdate);
        break;
      case "session":
        this.updateUserSessionOnly(userToUpdate);
        break;
      case "restore":
        this.refetchUserFromDb(userToUpdate)
    }
  }

  private updateUserPermanent = async (updatedUser: IStenaUser) => {
    try {
      this.setState({ employeeTableLoading: true });
      const updateStatus = await updateStenaUser(await requestAccessTokenBE(this.props.context), updatedUser);
      if (updateStatus.success) {
        const loadedUserResult = await getStenaUserById(await requestAccessTokenBE(this.props.context), updatedUser.id);
        if (!loadedUserResult.success) return;
        const newUsers = this.state.pendingUsers.map(user => {
          if (user.id === loadedUserResult.payload.id) {
            return loadedUserResult.payload
          }
          return user
        });
        this.setState({ pendingUsers: newUsers })
      }
    } catch (e: any) {

    } finally {
      this.setState({ employeeTableLoading: false })
    }
  }


  private updateUserSessionOnly = async (incommingUser: IStenaUser) => {
    try {
      this.setState({ employeeTableLoading: true });
      let updatedUser = { ...incommingUser }
      const token = await requestAccessTokenBE(this.props.context)
      const newAzureAccountsResult = await getAdUser(token, updatedUser);
      if (!newAzureAccountsResult.success) return
      updatedUser.azureAccounts = newAzureAccountsResult.payload;
      const newUsers = this.state.pendingUsers.map(user => {
        if (user.id === updatedUser.id) {
          return updatedUser
        }
        return user
      });
      this.setState({ pendingUsers: newUsers });
    }
    catch (er: any) {

    }
    finally {
      this.setState({ employeeTableLoading: false });
    }
  }

  private sendCheckPhoneEmail = async (note: IHrNoteRestObject, stid?: string) => {
    try {
      const token = await requestAccessTokenBE(this.props.context)
      const checkPhoneEmailResult = await sendCheckPhoneEmail(token, note.employeeId, stid);
      if (!checkPhoneEmailResult.success) {
        this.setState({ hrNotesForPending: { ...this.state.hrNotesForPending, [note.employeeId]: checkPhoneEmailResult.payload } })
      } else {
        console.log("No Email Sent")
      }
    }
    catch (error: any) {

    }
  }

  private refetchUserFromDb = async (userToUpdate: IStenaUser) => {
    try {
      this.setState({ employeeTableLoading: true });
      const token = await requestAccessTokenBE(this.props.context);
      const res = await getStenaUserById(token, userToUpdate.id);
      if (!res.success) return;
      const freshUser = res.payload;
      const newUsers = this.state.pendingUsers.map((user: IStenaUser) => {
        if (user.id === freshUser.id) return freshUser;
        return user;
      });
      this.setState({ pendingUsers: newUsers });
    } catch (e: any) {

    } finally {
      this.setState({ employeeTableLoading: false });
    }
  }

  private activatePendingUser = async (userToActivate: IStenaUser, azureInfo: AzureInfo) => {
    let activationResult = null
    try {
      this.setState({ employeeTableLoading: true })
      const activationResponse = await activateUserNew(await requestAccessTokenBE(this.props.context), userToActivate, azureInfo);
      if (!activationResponse.success) {
        activationResult = {
          activatedUser: userToActivate,
          messages: [{
            message: activationResponse.failMessage,
            severity: "red"
          }]
        };
        return;
      }
      const { activatedUser } = activationResponse.payload;
      activationResult = activationResponse.payload
      if (!activatedUser) {
        activationResult.activatedUser = userToActivate
        return;
      }

      const pendingUsers = this.state.pendingUsers.filter(u => u.id !== activatedUser.id);
      this.setState({ pendingUsers });
    } catch (e: any) {
      const errorMessageEmployeeTable = e instanceof Error ? e.message : typeof e === "string" ? e : "unknown error"
      activationResult = ({
        activatedUser: userToActivate,
        messages: [{ message: errorMessageEmployeeTable, severity: "red" }]
      });
    } finally {
      const activationResults = [...this.state.activationResults];
      activationResults.push(activationResult as IActivationResult);
      this.setState({ employeeTableLoading: false, activationResults: activationResults, activationResultsViewOpen: true });
    }
  }

  private sendNoteForUpdate = async (note: IHrNoteRestObject) => {
    try {
      const token = await requestAccessTokenBE(this.props.context)
      const updateResult = await updateNote(token, note);
      if (updateResult.success) {
        const newNote = updateResult.payload;
        const newNotes = { ...this.state.hrNotesForPending }
        newNotes[newNote.employeeId] = newNote;
        this.setState({
          hrNotesForPending: newNotes
        });
      }
    }
    catch (error: any) {

    }
  }

  private dispatchSms = async (note: IHrNoteRestObject, type: SmsType) => {
    try {
      const token = await requestAccessTokenBE(this.props.context)
      const smsResult = type === "ACCOUNT_ISSUE_DELAY"
        ? await sendAccountIssueDelaySms(token, note)
        : await sendAccountProcessDelaySms(token, note);
      if (smsResult.success) {
        const newNote = smsResult.payload;
        const newNotes = { ...this.state.hrNotesForPending };
        newNotes[newNote.employeeId] = newNote;
        this.setState({
          hrNotesForPending: newNotes
        });
      }
    }
    catch (error: any) {

    }
  }

  private resolveHrNote = async (note: IHrNoteRestObject) => {
    try {
      const removalResult = await resolveNote(await requestAccessTokenBE(this.props.context), note);
      if (removalResult.success) {
        const newAutoActivatedNotes = { ...this.state.hrNotesForAutoActivated }
        delete newAutoActivatedNotes[note.employeeId]
        this.setState({ hrNotesForAutoActivated: newAutoActivatedNotes });
      }
    } catch (e: any) {
    }
  }

  public render(): React.ReactElement<ILoggedInProps> {
    return (
      <div className="LoggedIn">
        <Header />
        {
          this.state.pageNotReady
            ? this.loadingView()
            : this.getView()
        }
      </div>
    );
  }
}

export default LoggedIn;