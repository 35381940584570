import { IActivationResult, IErrorMessage } from "../../types/types";


export function ActivationStatusDisplay(props: { result: IActivationResult }) {
    const { firstName, lastName, activatedUpn } = props.result.activatedUser || { firstName: "", lastName: "", activatedUpn: "" };
    return (
        <div>
            {firstName && <p>{`User: ${firstName} ${lastName}`}</p>}
            {activatedUpn && <p>{`Upn: ${activatedUpn}`}</p>}
            {props.result.messages.map((status: IErrorMessage) => {
                return <p key={status.message} style={{ color: status.severity }}>{`${status.message}`}</p>
            })}
        </div>
    )
}