import React from "react";
import MaterialTable, { Column, Options, Query } from "material-table";
import { ThemeProvider, createTheme, Button } from '@mui/material';
import { requestAccessTokenBE } from "../requestAccessToken";
import { formatDateString, getActivatedStenaUserCount, getActivatedStenaUsersWithOffset } from "../util";
import { IAzureUser, IHrDataMap, IHrNoteRestObject, IStenaUser, IStenaUserFetchFilter, Screensize } from "../types/types";
import './EmployeeTable.css';
import { AccessLevel, ActiveView } from "./LoggedIn";


interface IReadEmployeeTableProps {
  accessLevel: AccessLevel;
  context: any;
  screensize: Screensize;
  switchView?: (view: ActiveView) => void;
  hrNotes: IHrDataMap;
  resolveNote: (note: IHrNoteRestObject) => void;
}

interface IEmployeeTableState {
  fetchInProgress: boolean;
  currentData?: ITableData;
}

interface ITableData {
  data: IStenaUser[];
  page: number;
  totalCount: number;
}


class ReadEmployeeTable extends React.Component<IReadEmployeeTableProps, IEmployeeTableState> {

  constructor(props: IReadEmployeeTableProps) {
    super(props);
    this.state = {
      fetchInProgress: false,
    };
  }

  private getAzureUserFromStenaUser = (stenaUser: IStenaUser): IAzureUser | undefined => {
    const azureUser = stenaUser.azureAccounts[0];
    return azureUser;
  }



  private fetchTableData = async (query: Query<IStenaUser>): Promise<ITableData> => {
    this.setState({ fetchInProgress: true });
    try {

      const fetchFilter: IStenaUserFetchFilter = {
        offset: query.page * query.pageSize,
        count: query.pageSize,
        sortingField: query.orderBy?.field || "timeActivated",
        sortDesc: query.orderDirection === "desc",
        search: query.search,
      }
      const token = await requestAccessTokenBE(this.props.context)
      const usersRequest = await getActivatedStenaUsersWithOffset(token, fetchFilter)
      const userCount = await getActivatedStenaUserCount(token)

      if (!usersRequest.success || !userCount.success)
        throw new Error("Failed to fetch users");

      var result: ITableData = {
        data: usersRequest.payload,
        page: query.page,
        totalCount: userCount.payload
      }
      this.setState({ currentData: result });
      return result;
    }
    catch (error) {
      console.error(error);
      return this.state.currentData || { data: [], page: 1, totalCount: 10 };
    }
    finally {
      this.setState({ fetchInProgress: false });
    }
  }

  private activatedColumns: Array<Column<IStenaUser>> = [
    {
      title: "First Name",
      field: "firstName",
      filterPlaceholder: "Filter by first name!",
    },
    {
      title: "Last Name",
      field: "lastName",
    },
    {
      title: "Mail",
      field: "email",
    },
    {
      title: "Phone",
      field: "phoneNumber",
    },
    {
      title: "Upn",
      render: rowData => {
        let account = this.getAzureUserFromStenaUser(rowData);
        let color = account?.accountEnabled ? "black" : "red";
        return <p style={{ color }}>{account?.upn ?? "No account found"}</p>
      }
    },
    {
      title: "Stid",
      sorting: false,
      searchable: false,
      render: (rowData) => {
        const stid = this.getAzureUserFromStenaUser(rowData)?.stid || "";
        return <p>{stid}</p>
      }
    },
    {
      title: "SAM-account",
      sorting: false,
      searchable: false,
      render: (rowData) => {
        const sam = this.getAzureUserFromStenaUser(rowData)?.samAccountName || "";
        return <p>{sam}</p>
      }
    },
    {
      title: "Licenses",
      sorting: false,
      searchable: false,
      render: (rowData) => {
        const licenses = this.getAzureUserFromStenaUser(rowData)?.licenses;
        const licenseString = !!licenses && licenses.length > 0 ? licenses.reduce((prev, cur) => prev + " " + cur) : "";
        return <p>{licenseString}</p>
      }
    },
    {
      title: "Time Activated",
      defaultSort: "desc",
      field: "timeActivated",
      render: rowData => <p>{formatDateString(rowData.timeActivated)}</p>
    },
    {
      title: "Signed in",
      render: rowData => <p>{this.getAzureUserFromStenaUser(rowData)?.hasSignedIn ? "Yes" : "No"}</p>
    },
    {
      title: "Autoactivated",
      render: rowData => this.props?.hrNotes[rowData.id]?.activationIssues.includes("AUTO_ACTIVATION") ? "Yes" : ""
    }
  ];

  private activatedOptions: Options<IStenaUser> = {
    showTextRowsSelected: false,
    emptyRowsWhenPaging: false,
    addRowPosition: "first",
    searchFieldAlignment: "left",
    pageSize: 10,
    pageSizeOptions: [10, 100, 300, 500],
    sorting: true,
    debounceInterval: 1111,
    headerStyle: {
      color: '#000000',
      fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
      fontWeight: 700,
      fontSize: "13px",
    },
    rowStyle: {
      height: "100px"
    },
    filterRowStyle: {
      backgroundColor: '#494239',
      color: '#5E5E5E',
    },
  };

  render(): React.ReactNode {
    return (
      <div className="container">
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
        <ThemeProvider theme={createTheme()}>
          <div className="headerDiv">
            <h1>Activated Users</h1>
            {
              this.props.switchView && (
                <div className="switchDiv">
                  {
                    this.props.accessLevel === "hasAdminAccess"
                      ?
                      <Button onClick={() => this.props.switchView && this.props.switchView("admin")}>Admin</Button>
                      :
                      <Button onClick={() => this.props.switchView && this.props.switchView("hr")}>Hr</Button>
                  }
                </div>
              )
            }

          </div>
          <MaterialTable
            title=""
            isLoading={this.state.fetchInProgress}
            columns={this.activatedColumns}
            data={this.fetchTableData}
            options={this.activatedOptions}
          />
        </ThemeProvider>
      </div>
    )
  }
}

export default ReadEmployeeTable;