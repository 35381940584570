import { Button, FormControl, InputLabel, List, ListItem, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import { HrNoteTagKey, HrNoteTags, IHrNoteRestObject, SmsType, hrNotesTagImpl } from "../../types/types";
import * as React from 'react';
import { MsalContext } from "@azure/msal-react";
import './EditDialogHrComment.css'
import { AccessLevel } from "../LoggedIn";

interface IEditDialogHrCommentProps {
    accessLevel: AccessLevel;
    updateNote: (comment: IHrNoteRestObject) => void;
    sendSms: (comment: IHrNoteRestObject, type: SmsType) => void;
    sendCheckPhoneEmail: (comment: IHrNoteRestObject) => void;
    hrNote: IHrNoteRestObject
}

interface IEditDialogHrCommentState {
    currentComment: string;
    loading: boolean;
    emailSendTimeout: boolean;
}

const tagsAreDifferent = (newTags: HrNoteTagKey[], oldTags: HrNoteTagKey[]): boolean => {
    if (!newTags || !oldTags) {
        return true;
    }
    console.log("newTags")
    console.log(newTags)
    console.log("oldTags")
    console.log(oldTags)
    if (newTags.length !== oldTags.length) {
        console.log("capricola")
        return true;
    } else {
        const previousTagsSet = new Set(newTags);
        for (const tag of oldTags) {
            if (!previousTagsSet.has(tag)) {
                return true;
            }
        }
        return false;
    }
}

const tags: HrNoteTags = hrNotesTagImpl

class EditDialogHrComment extends React.Component<IEditDialogHrCommentProps, IEditDialogHrCommentState> {
    static contextType = MsalContext
    constructor(props: IEditDialogHrCommentProps) {
        super(props);
        this.state = {
            currentComment: "",
            loading: false,
            emailSendTimeout: false,
        }
    }

    componentDidUpdate(prevProps: Readonly<IEditDialogHrCommentProps>, prevState: Readonly<IEditDialogHrCommentState>, snapshot?: any): void {
        /*  if (prevState.loading){
             if (!(prevProps.hrNote && this.props.hrNote)) {
                 this.setState({loading: false});
             }
             const oldTags = prevProps.hrNote.tags;
             const newTags = this.props.hrNote.tags;
             if(tagsAreDifferent(oldTags, newTags)){
                 console.log("hej")
                 this.setState({loading: false});
             }
             
         } */
        if (prevProps.hrNote !== this.props.hrNote) {
            this.setState({ loading: false })
        }
    }


    setComment = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        this.setState({ currentComment: e.target.value });
    }

    sendComment = (): void => {
        // @ts-ignore
        const fullName = this.context?.accounts[0]?.name
        const firstName = fullName ? fullName.split(",").pop() : "Anonym"
        const { hrNote } = this.props;
        if (!!hrNote) {
            const { currentComment: commentContent } = this.state;
            hrNote.comments.push(`${firstName}: ${commentContent}`);
            this.props.updateNote(hrNote);
            this.setState({ currentComment: "" });
        }
    }

    sendCheckPhoneEmailAndSetTimeout = (hrNote: IHrNoteRestObject) => {
        this.setState({ emailSendTimeout: true });
        this.props.sendCheckPhoneEmail(hrNote);
        setTimeout(() => {
            this.setState({ emailSendTimeout: false });
        }, 3000);
    }

    sendTags = (e: SelectChangeEvent<HrNoteTagKey[]>) => {
        try {
            const newTags = e.target.value as HrNoteTagKey[];
            const { hrNote } = this.props;
            if (tagsAreDifferent(newTags, hrNote.tags)) {
                hrNote.tags = newTags;
                this.props.updateNote(hrNote);
                this.setState({
                    loading: true
                });
            }
        } catch (e: any) {

        }
    }

    hrDisApprove = () => {
        const note = this.props.hrNote;
        note.hrApprove = !note.hrApprove;
        note.hrPreferredUpn = note.hrApprove ? note.hrPreferredUpn : "";
        this.setState({ loading: true });
        this.props.updateNote(note);
    }

    render(): React.ReactNode {
        const hrNote = this.props.hrNote
        return (
            <div className="editDialogCommentContainer">
                <List className="commentsList">
                    {
                        this.props.hrNote.comments.reverse().map((value: string, index: number) => <ListItem key={index} divider>{value}</ListItem>)
                    }
                </List>
                <div className="dynamicInput">
                    <TextField label="Comment" multiline value={this.state.currentComment} onChange={this.setComment} style={{ width: "90%" }}></TextField>
                    <Button variant="contained" onClick={this.sendComment}>Add comment</Button>
                </div>
                <div className="staticInputs">
                    <FormControl style={{ width: "90%" }} disabled={this.state.loading}>
                        <InputLabel id="tags-select-label">Set Tags</InputLabel>
                        <Select
                            labelId="tags-select-label"
                            multiple
                            onChange={this.sendTags}
                            value={this.props.hrNote.tags}
                        >
                            {Object.entries(tags).map(t => <MenuItem value={t[0]} key={t[0]}>{t[1]}</MenuItem>)}
                        </Select>
                    </FormControl>
                    {this.props.accessLevel === "hasAdminAccess"
                        ? (
                            <div>
                                <Button variant="contained" disabled={this.props.hrNote.delaySmsSent} onClick={() => { this.props.sendSms(hrNote, "ACCOUNT_ISSUE_DELAY") }}>Send regular delayed sms</Button>
                                <Button variant="contained" disabled={this.props.hrNote.regionNotIncludedSmsSent} onClick={() => { this.props.sendSms(hrNote, "ACCOUNT_PROCESS_DELAY") }}>Send account process delayed sms</Button>
                                <Button variant="contained" disabled={this.state.emailSendTimeout} onClick={() => { this.sendCheckPhoneEmailAndSetTimeout(hrNote) }}>Send check phone email</Button>
                            </div>
                        )
                        : (
                            <div>
                                <p>Chosen account: {hrNote.status}</p>
                                <Button variant="contained" disabled={this.state.loading} onClick={this.hrDisApprove}>{`${hrNote.hrApprove ? "Disa" : "A"}pprove for activation`}</Button>
                            </div>

                        )
                    }
                </div>
            </div>
        )
    }
}

export default EditDialogHrComment