import { Component } from 'react';
import { AuthenticatedTemplate, MsalContext, UnauthenticatedTemplate } from "@azure/msal-react";
import LoggedIn from './components/LoggedIn';
import LoggedOut from './components/LoggedOut';




export default class App extends Component {
    static contextType = MsalContext;

    render() {
        const screensize = window.matchMedia("(max-width: 480px)").matches ? 0 : window.matchMedia("(max-width: 768px)").matches ? 1 : 2
        return (
            <div>
                <AuthenticatedTemplate>
                    <LoggedIn screensize={screensize} context={this.context} />
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <LoggedOut screensize={screensize}/>
                </UnauthenticatedTemplate>
            </div>
        );
    }
}