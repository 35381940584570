import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import LogoutIcon from '@mui/icons-material/Logout';
import './MicrosoftButton.css';

async function handleLogout(instance: any) {
  instance.logoutRedirect().catch((e: any) => {
    console.error(e);
  });
}

async function handleLogin(instance: any) {
  /*const response = await fetch('weatherforecast');
  console.log(response);*/

  instance.loginRedirect(loginRequest).catch((e: any) => {
    console.error(e);
  });
}

export const MicrosoftButton = (props: { signIn: boolean }) => {
  const { instance } = useMsal();

  return (
    <button
      className={props.signIn ? 'signInMicrosoftButton' : "signOutMicrosoftButton"}
      onClick={() => props.signIn ? handleLogin(instance) : handleLogout(instance)}
    >
      {props.signIn ? <><img alt="sign in button admin" className='microsoftLogo' src="https://docs.microsoft.com/en-us/azure/active-directory/develop/media/howto-add-branding-in-azure-ad-apps/ms-symbollockup_mssymbol_19.svg" /> <p>Admin login</p></> : <p>Sign out <LogoutIcon fontSize="small" className="signOutIcon" /></p>}
    </button>
  );
}